export const docChatBotBlog = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
  </head>
  <body class="c15 doc-content">
    <p class="c1">
      <span>Good documentation is not for the faint of heart! That may sound dramatic, but it&rsquo;s true. In fact, I used to almost ignore it, given how complex it could be to maintain and the fact that there was no clear return on investment. Only when our life started depending on it, errr&hellip; by that I mean, users&rsquo; happiness, did we take on the arduous task of putting the right information in the right place.</span>
      <span>&nbsp;And are we there yet? Nope! So says</span>
      <span class="c0">our chatbot! Let&rsquo;s then see what is required to maintain documentation for a database product up to date, what we did before our chatbot experiment, and how we are evolving and improving it now. </span>
    </p>
    <h2 class="c5" id="h.iqb6peyk6loh">
      <span class="c13">Query language and API</span>
    </h2>
    <p class="c1">
      <span class="c0">ApertureDB is a database purpose-built for multimodal AI, so it manages multimodal data like text, images, videos, embeddings, annotations, and application metadata. Our JSON-based query language makes it easier for us to unify management of these data types behind a simple and consistent interface. &nbsp;In this way, a user does not have to worry about dealing with multiple data backends or query languages any more. However, the uniqueness of our product comes with a need to thoroughly document the principles and specifications of our query language. </span>
    </p>
    <h3 class="c3" id="h.nuvjchm4h5xb">
      <span class="c8">Evolution in ApertureDB documentation tools</span>
    </h3>
    <p class="c1">
      <span class="c0">ApertureDB originally grew out of a project at Intel, where we started our documentation with GitHub Pages, but as our code grew it was always out of sync, since our documentation and code were written in different places. Something like Doxygen strings could be useful, but it was complicated to attach a lot of context or examples unless we wrote pages of structured comments in code files. We then moved to writing RST files for every command in our query interface with examples; that was our documentation for a long time until we realized we wanted richer documentation capabilities. &nbsp;Finally we landed on Docusaurus as our documentation tool. </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/doc_chat_blog/documentation.png" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span class="c0">We also had separate Python SDK documentation, since that was generated out of Python Docstrings. Even though much of our Python SDK is a simple wrapper for JSON queries, it was very inconvenient for anyone to look up how to do something because they had to go to two different repositories with little tying them together. As part of our migration to Docusaurus, we combined both sources of documentation into a unified documentation website, and also started introducing notebook examples to provide code samples to our users. &nbsp;This also made it easier for us to add cross-links between the different types of documentation.</span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <h3 class="c3" id="h.uisvt9i3wpo9">
      <span class="c8">Automatic evaluation of code snippets</span>
    </h3>
    <p class="c1">
      <span class="c0">As anyone maintaining API documentation can tell you, while it is very important to include many examples of the code required to perform various tasks, &nbsp;it&rsquo;s not easy to keep those examples in sync with changes to the API. The only way to ensure the sample code always works is to execute it as part of the continuous integration and deployment. At one of our quarterly team meetings, we decided to automate creation of our documentation code snippets from a test suite. It was one of our smarter ideas. Now we knew that any code we put in our docs would work and produce responses as we documented them. We no longer feared copy/paste errors!</span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/doc_chat_blog/auto_create_doc_examples.png" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <h2 class="c5" id="h.rvh1jbwcmueu">
      <span class="c13">Answering fundamental product questions</span>
    </h2>
    <p class="c1">
      <span>We have ventured into a very complex space where many different forms of AI are served by a bewildering array of different types of databases and related infrastructure. From early days, we had to answer questions around the need for our product, architectural details, design choices, deployment options, and cost/benefit analysis. The user journey starts with why they need a specific tool and what problems it can help them solve. Our next level of improvement was to introduce some context around the product we have built. It certainly helped when we could respond to a question by sharing a well thought-out link, instead of providing ad hoc responses on-the-fly. However, once we answered the why and what, we now needed to help our user find &ldquo;how-to&rdquo; without expecting them to know specific terminologies. The ability to semantically search for a concept, summarize a response, and point to relevant links is exactly why large language model (LLM) and retrieval augmented generation (RAG) </span>
      <span class="c14">
        <a class="c6" href="http://aperturedata.io/blog/multimodal-data-intro-part1" target="_blank">methods</a>
      </span>
      <span class="c0">&nbsp;have become so popular. That was our solution to the discoverability problem as described next. </span>
    </p>
    <h2 class="c5" id="h.ywsikdn6mf8u">
      <span class="c13">Here comes our RAG bot</span>
    </h2>
    <p class="c1">
      <span>We are always on the lookout for ways to make our users&rsquo; lives easier by integrating with popular AI support tools. We recently wrote an integration of ApertureDB to LangChain as a vector store and retriever. &nbsp;As part of this, we built an </span>
      <span class="c14">
        <a class="c6" href="http://aperturedata.io/demo-request" target="_blank">example demo on our website</a>
      </span>
      <span>&nbsp;(select the semantic search example)</span>
      <span>&nbsp;showing off a RAG (Retrieval-Augmented Generation) chain that answers questions from Wikipedia, using </span>
      <span class="c14">
        <a class="c6" href="https://cohere.com/blog/embedding-archives-wikipedia" target="_blank">an embeddings dataset provided by Cohere</a>.
      </span>
      <span class="c0">This LangChain-based implementation uses ApertureDB under the covers as the vectorstore / retriever for high-performance look up of documents that are semantically similar to the user&rsquo;s query.</span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/doc_chat_blog/semsearch_trial.png" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span class="c0">To test this RAG chain, we asked it questions about ApertureDB. Of course the answers were disappointing, as we don&rsquo;t yet have a Wikipedia article, and the LLM told us that it had insufficient data for a meaningful answer. Out of curiosity, we built another RAG chain that used a crawl of our marketing website and product documentation. Et voila. We started getting expected answers to questions like &ldquo;Can you store audio in ApertureDB&rdquo;? Or is &ldquo;ApertureDB a vector database&rdquo;?</span>
    </p>
    <p class="imgContainer">
			<span style="overflow: hidden; display: inline-block; margin: 0px; border: none; width: 100%; height: auto; box-sizing: border-box;">
        <img alt="" src="https://aperturedata-public.s3.us-west-2.amazonaws.com/website_images/doc_chat_blog/docs_chat.png" style="width: 60%; height: 60%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);" title="">
      </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span>This seemed like a great functionality to push to our documentation page, and why wouldn&rsquo;t we? We asked our chatbot questions like &ldquo;What command do I use for adding embeddings?&rdquo; and the answer gave a good description of how to do it, and also gave a reference to our </span>
      <span class="c10">AddDescriptor</span>
      <span class="c0">&nbsp;command. This is immensely more powerful than mere keyword search, because if people don&rsquo;t know our API or specific concepts, they can ask questions about what they want to do, and this chatbot will hopefully direct them to the right examples. </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <h2 class="c3" id="h.5b4u8osrxqs5">
      <span class="c8">The hunt for missing documentation</span>
    </h2>
    <p class="c1">
      <span>As more users started trying out our documentation bot, we started getting a broader set of user </span>
      <span>queries </span>
      <span class="c0">and realized that some of the chatbot&rsquo;s responses lacked details, said the answer wasn&rsquo;t available, or were just plain wrong. We ourselves knew the right answers and that sent us on a hunt through our documentation to find out why we didn&rsquo;t get the expected answer. It turns out, we had solved some of those problems for our customers or in our benchmark repositories but had never included the answers, either in our marketing website or in our documentation. Now we can look at the questions that resulted in insufficient or incorrect responses and introduce helpful and accurate information where it belongs. Ultimately, if we can help our users to find guidance easily, then it&#39;s a win for everyone.</span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <h2 class="c5" id="h.skqw7vt5o9oz">
      <span class="c13">How do we plan to improve this chat?</span>
    </h2>
    <p class="c1">
      <span class="c0">Our RAG chain chatbot is already a valuable addition to our documentation website. &nbsp;Not only is it a good demonstration of our software, but it also makes life easier for both users and our own developers. &nbsp;We don&rsquo;t plan to rest on our laurels, however, and we have many enhancements planned for the future.</span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span class="c0">We&rsquo;re closely monitoring users' queries and the answers given by the chatbot to see if they are complete, useful, and accurate. &nbsp;When answers fall short, we find ways to enhance or extend our documentation accordingly. &nbsp;In order to streamline this process, we plan to include a way for users to give immediate feedback on answers, for example with thumbs up and down buttons. &nbsp;Another thing we plan to do, taking a leaf from the methodology of modern AI research, is to use an LLM (Large Language Model) to assess each answer along multiple dimensions. </span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span class="c0">There are a lot of parameters to tune in an AI system, and a RAG chain is no exception. There is a lot we can do to improve the prompt, tweak the segmentation, and provide better context for question answering. &nbsp;We are also not taking advantage right now of ApertureDB&rsquo;s multimodal capabilities; it should be possible to fetch text, image, and video documents as a source of information. &nbsp;The only way is up!</span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c11">
      <span class="c7"><i>Last but not least, we will be documenting our journey and explaining all the components listed above on our blog, subscribe</span>
      <span class="c14 c7">
        <a class="c6" href="https://forms.gle/BdxgdUndw5FcsBQP6" target="_blank">here</a>.</i
      </span>
    </p>
    <p class="c12">
      <span class="c4"><i>I want to acknowledge the insights and valuable edits from Gavin Matthews, Drew Ogle, and Sonam Gupta.</i></span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
    <p class="c1 c2">
      <span class="c0"></span>
    </p>
  </body>
</html>
`;
