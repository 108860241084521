import { ResourceType } from '../../pages/Resources/Resources';
import ApertureDataSheets from '../images/Resources/apertureDataSheets.png';
import ApertureRetailDataSheets from '../images/Resources/retailDataSheet.jpg';
import DataPreparation from '../images/Resources/dataPreparation.png';
import OvercomingVisual from '../images/Resources/overcomingVisual.png';
import SpecializedDB from '../images/Resources/specializedDB.png';
import VectorDB from '../images/Blogs/VectorDBIntro.jpg';
import ApertureDB2 from '../images/Blogs/Aperture2.jpg';
import inVisualDataset from '../images/Blogs/InVisualDataset.jpg';
import DataPrep from '../images/Blogs/DataPrep.jpg';
import Vaccination from '../images/Resources/vaccination.png';
import Ventures from '../images/Resources/ventures.png';
import DSIntroVideo from '../images/Resources/introvideo1.png';
import BizIntroVideo from '../images/Resources/bizintro.png';
import VectorDBIntro from '../images/Resources/FaceSimilaritySearch.png';
import DockerSetupVideo from '../images/Resources/semantic_search.png';
import EcommDataChallenges from '../images/Resources/EcommerceDataChallenges.jpg';
import DataCouncilVideo from '../images/Resources/datacouncil.png';
import DatacampCVPanel from '../images/Resources/DatacampCVPanel.png';
import DatacampCodealong from '../images/Resources/DatacampCodealong.jpg';
import MultimodalWeekly from '../images/Resources/MultimodalWeekly.jpg';
import AIcampVectorDBVideo from '../images/Resources/aicamp.jpg';
import FAQ from '../images/Resources/faq.png';
import VisualCloud from '../images/Resources/visualCloud.png';
import VisualData from '../images/Resources/visualData.png';
import VisualDataMachine from '../images/Resources/visualDataMachine.png';
import BadgerCaseStudy from '../images/CaseStudies/BadgerTechnologies/case-study-logo.png';
import retailAiImg from '../images/Blogs/retail_blog.png';
import industrialAiImg from '../images/Blogs/inspection1.jpg';
import LifeSciencesImg from '../images/Blogs/LifeSciences.jpg';
import purporseBuiltDatabaseImg from '../images/Blogs/purposeBuiltDatabaseImg.jpg';
import YourMultimodalDataEvolvingImg from '../images/Blogs/MultimodalDataEvolving.png';
import addDockerLaunchBlogImg from '../images/Blogs/docker.png';

const resources: ResourceType[] = [
	{
		title:
			'Badger Technologies Uses ApertureDB to Solve "Wrong Product" Placement Problems at Scale',
		description:
			'The company uses ApertureDB to enhance vector search performance on their library of embeddings, achieving a 2.5x improvement in...',
		image: BadgerCaseStudy,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.aperturedata.io/case-studies/1',
		readMoreText: 'READ MORE',
	},
	{
		title: 'ApertureDB Now Available on DockerHub',
		description:
			'Getting started with ApertureDB has never been easier or safer...',
		image: addDockerLaunchBlogImg,
		type: 'PRODUCT',
		link: '/blog/docker-launch',
		readMoreText: 'READ MORE',
	},
	{
		title:
			'Setup Guide for ApertureDB Docker',
		description:
			'This video shows you how to get started with hands-on evaluation using a Docker setup',
		image: DockerSetupVideo,
		type: 'VIDEOS',
		link: 'https://youtu.be/VKtXzTOMTL0?feature=shared',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'ApertureDB Data Sheet',
		description: 'This one pager provides an overview of ApertureDB',
		image: ApertureDataSheets,
		type: 'PRODUCT',
		link: 'https://drive.google.com/file/d/1VITGVMBkkb8BCwumTQqOrz5M2R02xMeT/view?usp=drivesdk',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Why Do We Need A Purpose-Built Database For Multimodal Data?',
		description:
			'Recently, data engineering and management has grown difficult for companies...',
		image: purporseBuiltDatabaseImg,
		type: 'BLOGS',
		link: '/blog/purpose-built-database',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Your Multimodal Data Is Constantly Evolving - How Bad Can It Get?',
		description:
			'The data landscape has dramatically changed in the last two decades...',
		image: YourMultimodalDataEvolvingImg,
		type: 'BLOGS',
		link: '/blog/challenges-with-multimodal-data-evolving',
		readMoreText: 'READ MORE',
	},
	{
		title:
			'Are Vector Databases Enough for Multimodal Data?',
		description:
			'Learn from user examples of whats needed from data architecture for multimodal AI...',
		image: AIcampVectorDBVideo,
		type: 'EVENTS',
		link: 'https://www.youtube.com/watch?v=Q8xX8NfFAkQ&t=4228s',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'ApertureDB Retail Data Sheet',
		description: 'An overview of ApertureDB for Smart Retail / E-commerce',
		image: ApertureRetailDataSheets,
		type: 'APPLIED',
		link: 'https://drive.google.com/open?id=1saZmtHZXd_gTlPKQg0jzrsZMTga78H3R&usp=drive_fs',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Transforming Retail and Ecommerce with Multimodal AI',
		description:
			'Multimodal AI can boost retail sales but is hindered by data challenges ...',
		image: retailAiImg,
		type: 'APPLIED',
		link: '/blog/transforming-retail-ecommerce-multimodal-ai',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Accelerate Industrial and Visual Inspection with Multimodal AI',
		description:
			'From worker safety to detecting product defects to overall quality control...',
		image: industrialAiImg,
		type: 'APPLIED',
		link: '/blog/accelerate-inspection-multimodal-ai',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Challenges and Triumphs: Multimodal AI in Life Sciences',
		description:
			'AI presents a new and unparalleled transformational opportunity for the life sciences sector...',
		image: LifeSciencesImg,
		type: 'APPLIED',
		link: '/blog/life-sciences',
		readMoreText: 'READ MORE',
	},
	{
		title:
			'Why do businesses need a purpose-built database for visual analytics?',
		description:
			'Learn why businesses benefit from a specialized database for data science on visual data.',
		image: BizIntroVideo,
		type: 'VIDEOS',
		link: 'https://youtu.be/tDWK3CQZhso',
		readMoreText: 'WATCH NOW',
	},
	{
		title:
            'Data science challenges in extracting value from image and video based data',
		description:
            'Learn why data scientists and ML practitioners working on visual data need a specialized database.',
		image: DSIntroVideo,
		type: 'VIDEOS',
        link: 'https://www.youtube.com/watch?v=uSP7qjeF5A4',
		readMoreText: 'WATCH NOW',
	},
    {
		title:
            'E-commerce Data Science Needs Sophisticated Visual Data Management',
		description:
            'Learn how ApertureDB benefits e-commerce usecases like personalized recommendations, attribute classidication.',
		image: EcommDataChallenges,
		type: 'APPLIED',
        link: 'https://www.youtube.com/watch?v=D0TGB9jYc3E',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'Frequently Asked Questions',
		description: 'Common questions from our customers and our responses, before they start using ApertureDB.',
		image: FAQ,
		type: 'PRODUCT',
		link: '/faq',
		readMoreText: 'READ MORE',
	},
	{
		title:
			'Simplifying Image Recognition using ApertureDB and Python',
		description:
			'Learn about workflows for image and video data stored in ApertureDB ...',
		image: DatacampCodealong,
		type: 'PRODUCT',
		link: 'https://www.youtube.com/watch?v=fdhzu3sqcMc',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'Are Vector Databases Enough for Visual Data Use Cases',
		description:
			'Applications need original data and metadata together with vector data ...',
		image: VectorDB,
		type: 'BLOGS',
		link: '/blog/are-vector-databases-enough',
	},
	{
		title:
			'AI for Visual Data: Computer Vision in Business',
		description:
			'Panel on high value use-cases for image & video data, best practices for managing and analyzing visual data ...',
		image: DatacampCVPanel,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.youtube.com/watch?v=dqJApFjoMUI',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'Find Matching Faces: Metadata and Vector Search in ApertureDB',
		description:
			'See how to search for faces based on metadata constraints and facial similiarity matching...',
		image: VectorDBIntro,
		type: 'PRODUCT',
		link: 'https://www.youtube.com/watch?v=Ta7G_ASgxnA',
		readMoreText: 'WATCH NOW',
	},
	{
		title:
			'Are Vector Databases Enough for Visual Data Use Cases?',
		description:
			'Overview and demo of building image and video applications that need Vector Search...',
		image: MultimodalWeekly,
		type: 'EVENTS',
		link: 'https://youtu.be/00eTznaE7lI?feature=shared&t=124',
		readMoreText: 'WATCH NOW',
	},
	{
		title:
			'Just Because Your Data Is Unstructured Doesn’t Mean it Should be Onerous',
		description:
			'Learn why visual data today needs special treatment and how this can be achieved',
		image: DataCouncilVideo,
		type: 'EVENTS',
		link: 'https://youtu.be/CC-ARLwSOWc',
		readMoreText: 'WATCH NOW',
	},
	{
		title:
			'ApertureDB: Designing a Purpose-built System for Visual Data and Data Science',
		description:
			'Learn why visual data needs special treatment now, how this can be achieved',
		image: Vaccination,
		type: 'EVENTS',
		link: 'https://www.youtube.com/watch?v=ji_L-GNVnwk',
		readMoreText: 'WATCH NOW',
	},
	{
		title: 'ApertureDB 2.0: Redefining Visual Data Management for AI',
		description:
			'ApertureDB is a production ready, purpose-built database for visual AI ...',
		image: ApertureDB2,
		type: 'PRODUCT',
		link: '/blog/aperture-db-2',
	},
	{
		title: 'What’s in Your Visual Dataset?',
		description:
			'Understanding and debugging visual data sets is challenging but essential ...',
		image: inVisualDataset,
		type: 'BLOGS',
		link: '/blog/whats-in-your-visual-dataset',
	},
	{
		title: 'How do you find what’s in your image or video datasets?',
		description:
			'See how ApertureDB Web Frontend simplifies navigating large collections of visual data...',
		image: DataPrep,
		type: 'PRODUCT',
		link: 'https://www.youtube.com/watch?v=U7KejMkIRfE',
		readMoreText: 'WATCH NOW',
	},
	{
		title: '2048 Ventures: Our Investment in ApertureData',
		description:
			'Learn why we think ApertureData is going to transform visual data management for ML',
		image: Ventures,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.2048.vc/blog/our-investment-in-aperturedata',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Visual Data Pack Rat to Explorer',
		description:
			'Learn how the Intel Research Center for Visual Cloud Systems gave birth to ...',
		image: VisualData,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.linkedin.com/pulse/visual-data-pack-rat-explorer-jim-blakley/',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Overcoming Visual Analysis Paralysis',
		description:
			'Read this article to learn how to overcome the massive scale challenges of visual ...',
		image: OvercomingVisual,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.linkedin.com/pulse/overcoming-visual-analysis-paralysis-jim-blakley/',
		readMoreText: 'READ MORE',
	},
	{
		title: 'Seeing Further Down the Visual Cloud Road',
		description:
			'Learn why visual data today needs special treatment and how this can be achieved',
		image: VisualCloud,
		type: 'INDUSTRY EXPERTS',
		link: 'https://www.linkedin.com/pulse/seeing-further-down-visual-cloud-road-jim-blakley/',
	},
	{
		title: 'Building a Specialized Database for Analytics on Images and Videos',
		description:
			'The business case for a database purpose-built for analytics on image ...',
		image: SpecializedDB,
		type: 'BLOGS',
		link: '/blog/specialized-database-analytics-images-videos',
	},
	{
		title: 'Minute-Made Data Preparation with ApertureDB',
		description:
			'A walkthrough of how ML with images and annotations can be significantly simplified',
		image: DataPreparation,
		type: 'PRODUCT',
		link: '/blog/minute-made-data-preparation',
	},
	{
		title:
			'Managing Visual Data for Machine Learning and Data Science. Painlessly.',
		description:
			'Read this blog to learn why we started ApertureData and how our unique ....',
		image: VisualDataMachine,
		type: 'BLOGS',
		link: '/blog/visual-data-machine-learning-data-science',
	},
];

export default resources;
