import { DemoCaseType } from '../../pages/Demo/DemoRequest';

const cases: DemoCaseType[] = [
	{
		name: 'coco',
		title: 'Data preparation for ML using metadata, annotations, or vector search over COCO/YFCC dataset',
	},
	{
		name: 'faces',
		title: 'Find matching faces with vector and metadata search over CelebA dataset',
	},
	{
		name: 'semanticsearch',
		title: 'NLP based search over a corpus of Wikipedia pages using Langchain + ApertureDB',
	},
];

export default cases;
