import { NewType } from '../../pages/News/News';
//import ApertureData from '../images/News/apertureData.png';
import BusinessInsider from '../images/News/businessInsider.png';
import Scale from '../images/News/scale.png';
import TC from '../images/News/tc.png';
import VentureBeat from '../images/News/ventureBeat.png';
import WomenInCloud from '../images/News/womenInCloud.png';

const news: NewType[] = [
	{
		title:
			'ApertureData is building a database focused on images with $3M seed',
		description:
			'When Vishakha Gupta and her co-founder Luis Remis were working together at Intel Labs in 2016, they were charged with figuring out how to manage growing amounts of visual data ...',
		image: TC,
		date: 'February 8, 2022',
        link: 'https://techcrunch.com/2022/02/08/aperturedata-is-building-a-database-focussed-on-images-with-3m-seed/?guccounter=1',
	},
	{
		title:
			'31 startups in the red-hot $240 billion market for Big Data and analytics poised to explode in 2021, according to top VCs',
		description:
			'We asked top venture capitalists to name the most promising Big Data startups of 2021.',
		image: BusinessInsider,
		date: 'Sep 28, 2021',
        link: 'https://www.businessinsider.com/31-big-data-analytics-startups-big-according-to-vcs-2021-8',
	},
	{
		title:
			'ApertureDasta Awarded Competitive Grant From the National Science Foundation',
		description:
			'ApertureData has been awarded a National Science Foundation (NSF) Small Business Innovation Research (SBIR) grant for $225,000 to conduct research and development (R&D) ...',
		image: WomenInCloud,
		date: 'June 25, 2020',
		link: 'https://www.womenincloud.com/aperturedata-awarded-competitive-grant-from-the-national-science-foundation/',
	},
	{
		title:
			'Interview with Vishakha Gupta-Cledat: Issues Emerging in the Field of Data Management',
		description:
			'Vishakha Gupta-Cledat, CEO of @ApertureData: on issues emerging in the field of data management and her upcoming talk at #ScaleByTheBay 2019.',
		image: Scale,
		date: 'August 29, 2019',
		link: 'https://lnkd.in/gzbShi7',
	},
	{
		title: 'Tech Showcase Demo: Aperture Data | VB Transform 2019',
		description:
			'Tech showcase demos: Mesmer; Aperture Data; Spoke; Kinetica; Inference Solutions',
		image: VentureBeat,
		date: 'Jul 15, 2019',
        link: 'https://bit.ly/2Yaund4',
	},
	{
		title: 'Second Transform 2019: AI Showcase Highlights',
		description:
			'ApertureData wants to take machine learning to a new level by using a unified approach to visual data. Its solution is concerned with not just data, but metadata ...',
		image: VentureBeat,
		date: 'July 10, 2019',
		link: 'https://venturebeat.com/ai/second-transform-2019-ai-showcase-highlights-people-and-processes/',
	},
];

export default news;
